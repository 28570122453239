import Vue from 'vue'
import { i18n } from '@/i18n'

import LazyLoad from 'vue-make-lazy'
Vue.component('LazyLoad', LazyLoad)

// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Icon from '@kvass/vue2-icon'
Vue.component('FontAwesomeIcon', Icon)
Vue.component('Icon', Icon)

import { ButtonComponent, setup as ButtonSetup } from 'vue-elder-button'
ButtonSetup({
  type: 'button',
  labels: {
    loading: i18n.t('loading', { resource: undefined }) + '...',
    error: i18n.t('somethingWentWrong'),
    confirm: i18n.t('areYouSure'),
    success: i18n.t('completed'),
  },
  listeners: {
    click() {
      if (!window.Kvass || !this.$attrs.track) return
      const { event, ...data } = this.$attrs.track
      Kvass.emit('track', { event, data })
    },
  },
})
Vue.component('ButtonComponent', ButtonComponent)

import onError from '@/components/vendors/vue-elder-loader/onError'
import { setup as LoaderSetup, LoaderComponent as Loader } from 'vue-elder-loader'
LoaderSetup({
  theme: 'overlay',
  icon: { icon: ['fad', 'spinner-third'], size: '2x' },
  messages: {
    loading: i18n.t('loading', { resource: undefined }) + '...',
    error: onError,
  },
  delay: 200,
})
Vue.component('Loader', Loader)

import { setup as MediaRenderSetup } from '@kvass/media-render'
MediaRenderSetup({
  consentBlockMessage: i18n.t('cookieVideoBlockMessage'),
  consentButtonLabel: i18n.t('editConsents'),
})

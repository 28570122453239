<template>
  <div class="residential-cover__wrapper content">
    <Slider
      class="residential-cover"
      v-if="items.length"
      :value="items"
      manualNavigation
      display-thumbnail
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Slider from '@kvass/media-render/Slider'

export default {
  computed: {
    ...mapState('Residential', ['item']),
    items() {
      return this.$path('item.media.cover') || []
    },
  },
  components: {
    Slider,
  },
}
</script>

<style lang="scss">
.residential-cover {
  @include aspect-ratio(16, 9);

  &__wrapper {
    height: 50vh;
    background-color: css-alpha('primary', 0.07);

    @include respond-below('phone') {
      height: initial;
    }
  }
}
</style>

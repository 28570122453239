<template>
  <MapComponent class="project-map" :value="$path('address', item)" />
</template>

<script>
import MapComponent from '@/components/Map'
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('Project', ['item']),
  },
  components: {
    MapComponent,
  },
}
</script>

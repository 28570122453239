<template>
  <ShopIntro v-if="item" class="residential-shop-intro" :value="item" />
</template>

<script>
import { mapState } from 'vuex'
import ShopIntro from '@kvass/shop-intro'

export default {
  computed: {
    ...mapState('Residential', ['item']),
  },
  components: {
    ShopIntro,
  },
}
</script>

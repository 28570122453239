<template>
  <footer class="footer">
    <FooterContent
      v-if="customFooterContent.length"
      :value="customFooterContent"
      class="footer__custom-content container"
    />
    <div class="footer__container container">
      <div>
        <img :src="logo" width="150" :alt="item.name" />
        <div class="footer__copyright">
          &copy; {{ year }} - {{ $t('pageMadeBy') }}
          <a :href="`https://www.kvass.no?ref=${currentUrl}`" target="_blank">Kvass</a>
        </div>
        <div class="footer__links">
          <a :href="`/api/legal/privacy/project/${item.id}`" target="_blank">
            {{ $t('privacyPolicy') }} / {{ $t('cookiePolicy') }}
          </a>
          <a href="#" @click.prevent="editConsents">{{ $t('editConsents') }}</a>
        </div>
      </div>

      <so-me-footer v-if="someCustomField.length !== 0" :value="someCustomField" />

      <div class="footer__menu">
        <strong class="footer__title">{{ $t('menu') }}</strong>
        <template v-for="(item, index) in navigation">
          <ActionComponent
            v-if="$path('action.name', item) !== $path('name', $route)"
            class="footer__menu-action"
            :class="{ 'footer__menu-action--empty': !item.action }"
            v-bind="item"
            :key="index"
          />
          <template v-if="item.items && item.items.length">
            <ActionComponent
              v-for="(nested, ni) in item.items"
              class="footer__menu-action footer__menu-action--indent"
              v-bind="nested"
              :key="index + '_' + ni"
            />
          </template>
        </template>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapState } from 'vuex'
import API from '@/api'
import { GetCustomField } from '@/utils'

import FooterContent from '@kvass/template-footer-content'
import ActionComponent from 'vue-elder-navigation/src/action'
import SoMeFooter from '@/components/SoMeFooter.vue'

export default {
  props: {
    navigation: Array,
    cookieManager: {
      type: Object,
      defualt: () => ({}),
    },
  },
  data() {
    return {
      year: new Date().getFullYear(),
    }
  },
  computed: {
    ...mapState('Root', ['item']),
    customFooterContent() {
      if (!this.item) return

      const content = GetCustomField('footer-content-v2', this.item.customFields) || []
      if (content.some(i => Boolean(this.$path('content', i) || this.$path('image', i)))) {
        return content.map(i => {
          let base = ''
          if (i.image) base = `<img class="mb-4" style="max-width: 230px;" src=${i.image.url}>`
          if (i.content) base = `${base}<div> ${i.content} </div>`
          return base
        })
      }

      return GetCustomField('footer-content', this.item.customFields) || []
    },
    someCustomField() {
      if (!this.item) return
      return GetCustomField('social-media', this.item.customFields) || []
    },
    logo() {
      //return logo based on primary-color lightness
      let backgroundLightness = getComputedStyle(document.documentElement).getPropertyValue(
        '--primary-l',
      )
      if (parseFloat(backgroundLightness) < 70) return API.logoInverted
      return API.logo
    },
    currentUrl() {
      return window.location.href
    },
  },
  methods: {
    editConsents() {
      this.cookieManager.show()
    },
  },
  components: {
    ActionComponent,
    FooterContent,
    SoMeFooter,
  },
}
</script>

<style lang="scss">
.footer {
  padding: 6rem 0;
  background-color: var(--primary);
  color: var(--primary-contrast);

  &__container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 2rem;
  }

  &__copyright {
    margin-top: 2rem;

    a {
      font-weight: bold;
    }
  }

  &__menu {
    display: flex;
    flex-direction: column;

    a {
      & + a {
        margin-top: 0.5rem;
      }
    }
  }

  &__menu {
    &-action {
      padding: 0;
      background-color: transparent !important;
      color: inherit;
      font-weight: inherit;

      &--indent {
        margin-left: 0.5rem;
      }

      & + & {
        margin-top: 0.5rem;
      }
    }
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  p {
    margin-top: 0;
  }

  &__custom-content {
    margin-bottom: 4rem;
    a {
      text-decoration: underline;
    }
  }

  &__title {
    display: block;
    opacity: 0.5;
    margin-bottom: 1rem;
  }

  &__links {
    display: flex;
    flex-direction: column;
    margin-top: 0.5rem;
    gap: 0.5rem;
  }

  &__social-icons {
    a {
      margin-right: 0.8rem;
    }
  }

  .user-profile {
    display: none;
  }
}
</style>

<template>
  <ImageText
    v-if="value.length"
    :value="value"
    theme="sections"
    caption-placement="outside"
    class="additional-info"
  >
    <template #content="{ description }">
      <div class="additional-info__description" v-html="description" />
    </template>
  </ImageText>
</template>

<script>
import { mapState } from 'vuex'
import { GetCustomField } from '@/utils'

import ImageText from '@kvass/template-image-text'

export default {
  computed: {
    ...mapState('Project', ['item']),
    value() {
      let data = GetCustomField('additional-info', this.item.customFields) || []
      return data.map(item => {
        return {
          ...item,
          description: item.content,
        }
      })
    },
  },
  components: {
    ImageText,
  },
}
</script>



<template>
  <Flatfinder class="project-flatfinder" :value="item.flatfinders" :properties="properties" />
</template>

<script>
import { mapState } from 'vuex'
import Flatfinder from '@kvass/flatfinder'

export default {
  computed: {
    ...mapState('Project', ['item']),
    properties() {
      return KvassConfig.get('siteSettings.featuredResidentialProperties') || []
    },
  },
  components: {
    Flatfinder,
  },
}
</script>

<template>
  <Loader :value="promises.fetch" theme="default">
    <div class="project-page">
      <Hero class="section__hero" />
      <section
        class="section section__info"
        :class="{ 'section__info--full-size': !(showRoles || showOpenhouse) }"
      >
        <div class="container">
          <div class="grow-1" v-if="showRoles || showOpenhouse">
            <Openhouse v-if="showOpenhouse" class="mb-6" />
            <Roles v-if="showRoles" />
          </div>
          <Info class="project-info grow-1" />
        </div>
      </section>
      <section v-if="showTimeline" class="section section__timeline section--no-p">
        <div class="container">
          <Timeline />
        </div>
      </section>
      <section class="section__additional-info">
        <div v-if="showAdditionalSections" class="container">
          <ScrollAnchor value="additional-info" offset="-8rem" :delay="750" />
          <AdditionalInfo />
        </div>
      </section>
      <section v-if="showFlatfinder" class="section section__flatfinder">
        <ScrollAnchor value="flatfinder" offset="-8rem" :delay="750" />
        <Flatfinder />
      </section>
      <section v-if="showResidentials" class="section section__residentials">
        <div class="container">
          <ScrollAnchor value="residentials" offset="-8rem" :delay="750" />
          <Residentials />
        </div>
      </section>
      <section v-if="showResidentialTemplates" class="section section__residential-templates">
        <div class="container">
          <ScrollAnchor value="residential-templates" offset="-8rem" :delay="750" />
          <ResidentialTemplates />
        </div>
      </section>
      <section class="section section__lead">
        <div class="container">
          <ScrollAnchor value="lead" offset="-8rem" :delay="750" />
          <Lead />
        </div>
      </section>
      <section class="section section__attachments" v-if="$path('media.attachments.length', item)">
        <div class="container">
          <ScrollAnchor value="attachments" offset="-8rem" :delay="750" />
          <Attachments />
        </div>
      </section>
      <section
        v-if="$path('media.gallery.length', item)"
        class="section section__images section--no-p"
      >
        <ScrollAnchor value="gallery" offset="-8rem" :delay="750" />
        <Masonry />
      </section>
      <section class="section section__map section--no-p">
        <ScrollAnchor value="map" offset="-8rem" :delay="750" />
        <Map />
      </section>
    </div>
  </Loader>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ProjectComponents from '@/components/Project'
import Residentials from '@/components/Residentials'
import ScrollAnchor from '@kvass/scroll-anchor'
import BrowserApiMixin from '@/mixins/browser-api'
import { GetCustomField } from '@/utils'

export default {
  mixins: [BrowserApiMixin('project')],
  computed: {
    ...mapState('Project', ['promises', 'item']),
    showFlatfinder() {
      return this.$path('item.flatfinders.length') && KvassConfig.get('stats.total')
    },
    showOpenhouse() {
      if (!this.item.id) return false
      if (this.$path('item.openhouses.length')) return true
      return !KvassConfig.get('siteSettings.hideNoOpenhouse')
    },
    showRoles() {
      if (!this.item.id) return false
      return this.$path('item.roles.salesman.length')
    },
    showResidentialTemplates() {
      if (!this.item) return false
      return this.$path('residential-templates.references.length', this.item.customFields)
    },
    showAdditionalSections() {
      if (!this.item) return false
      let additionalInfo = this.$path('additional-info', this.item.customFields) || []

      return additionalInfo.some(i => Boolean(i.image || i.content))
    },
    showResidentials() {
      return KvassConfig.get('stats.total') > 0
    },
    showTimeline() {
      if (!this.item) return
      return this.$path('item.customFields.project-progress.0.content')
    },
  },
  methods: {
    ...mapActions('Project', ['fetch']),
    GetCustomField,
  },
  created() {
    this.fetch()
  },
  metaInfo() {
    return {
      title: this.$t('home'),
    }
  },
  components: {
    ...ProjectComponents,
    Residentials,
    ScrollAnchor,
  },
}
</script>

<style lang="scss">
.project-page {
  @for $i from 1 through 15 {
    & > *:nth-child(#{$i}) {
      order: $i * 10;
    }
  }

  .section {
    &--no-pb {
      padding-bottom: 0;
    }

    &--no-p {
      padding-top: 0;
      padding-bottom: 0;
    }

    &__lead {
      background-color: hsla(var(--primary-h), var(--primary-s), var(--primary-l), 0.05);
    }

    &__info {
      .container {
        display: grid;
        grid-gap: 2rem 6rem;
        grid-template-columns: repeat(2, 1fr);

        @include respond-below('phone') {
          grid-template-columns: 1fr;

          & > *:first-child {
            order: 2;
          }

          & > *:nth-child(2) {
            order: 1;
          }
        }
      }

      &--full-size {
        .project-info {
          grid-column-end: span 2;
          max-width: 700px;
          margin: 0 auto;
        }
      }
    }
  }
}
</style>

<template>
  <Openhouse class="project-openhouse" :value="item.openhouses" />
</template>

<script>
import { mapState } from 'vuex'
import Openhouse from '@/components/Openhouse'

export default {
  computed: {
    ...mapState('Project', ['item']),
  },
  components: {
    Openhouse,
  },
}
</script>

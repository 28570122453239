import Bus from './bus'
import { eventBus as FlatfinderBus } from '@kvass/flatfinder/src/Component.vue'

FlatfinderBus.on('onChange', function() {
  Bus.emit('flatfinder:onChange', ...arguments)
})

Bus.on('flatfinder:setCurrent', function() {
  FlatfinderBus.emit('setCurrent', ...arguments)
})

window.Kvass.migrate(Bus)
export default Bus

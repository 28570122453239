<template>
  <LoaderComponent :delay="0" value theme="default" />
</template>

<script>
import { LoaderComponent } from 'vue-elder-loader'
export default {
  components: {
    LoaderComponent,
  },
}
</script>

<template>
  <div class="project-info">
    <div class="project-info__description" v-html="description"></div>
    <BrochureComponent :value="brochure" class="project-info__brochure" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { RandomBetween } from '@/utils'

import BrochureComponent from '@/components/Brochure'

export default {
  computed: {
    ...mapState('Project', ['item']),
    brochure() {
      return this.$path('item.media.brochure') || []
    },
    description() {
      return (
        this.$path('item.description') ||
        new Array(35)
          .fill(0)
          .map(
            () =>
              `<span class="project-info__description-sample" style="width: ${RandomBetween(
                30,
                150,
              )}px"></span>`,
          )
          .join('')
      )
    },
  },
  components: {
    BrochureComponent,
  },
}
</script>

<style lang="scss">
.project-info {
  &__title {
    margin-bottom: 1.5rem;
    font-size: 2em;
  }

  &__description {
    line-height: 1.6;

    &-sample {
      display: inline-block;
      height: 5px;
      background-color: $border-color;
      margin-right: 0.5em;
    }
  }

  &__brochure {
    margin-top: 1.5rem;
    display: inline-block;
  }
}
</style>
